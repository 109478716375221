import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../blogPage/One"
import Two from "../blogPage/Two"
import Footer from "../components/Footer"

const BlogPage = ({ data }) => (
  <Fragment>
    <Helmet
      title="Articles About Stock Picks, Financial Advice & Stock Market Research"
      descr="ETF Stock Report offers financial blog post articles about stock market research and analysis to help build a portfolio that outperforms stock indexes, mutual funds, and ETFs."
      slug="/blog"
    />
    <Header />
    <One />
    <Two data={data} />
    <Footer />
  </Fragment>
)

export default BlogPage

export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: "Blog" } } } } }
    ) {
      nodes {
        id
        title
        excerpt
        date
        slug
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`
