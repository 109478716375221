import React from "react"
import { Link } from "gatsby"
import * as styles from "./styles.module.css"

function fmtDate(str) {
  const obj = new Date(str)
  const m = obj.getMonth() + 1
  const d = obj.getDate()
  const y = obj.getFullYear().toString()

  return `${m}/${d}/${y.slice(-2)}`
}

const Two = ({ data }) => {
  return (
    <section className={styles.two}>
      <div className={styles.container}>
        {data.allWpPost.nodes.map(node => (
          <div key={node.slug}>
            <p className={styles.title}>
              <span>{node.title}</span>
              <small>{fmtDate(node.date)}</small>
            </p>

            <div className={styles.excerptContainer}>
              <div
                className={styles.excerpt}
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
              {node.featuredImage && (
                <img
                  src={node.featuredImage.node.sourceUrl}
                  alt={node.featuredImage.node.altText}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Two
